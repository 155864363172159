<script setup lang="ts">
import type { Map as MapLibre } from 'maplibre-gl'
import type { VNode } from 'vue'
import { type LayerInfo, LayerRegistryKey } from './maplibreUtils'

const map = inject<Ref<MapLibre | undefined>>('map', ref())
const layers = ref<LayerInfo[]>([])
const slots = useSlots()

// Get the order of the layers in the slot
const slotLayersOrder = computed(() => {
  // Get components from default slot
  const defaultSlotComponents = slots.default?.({}) ?? []

  // Find all MapLibreLayer components and extract their layer-ids
  const layerIds = defaultSlotComponents
    .filter((vnode: VNode) => {
      const type = vnode.type as { name?: string }
      return type && typeof type === 'object' && type.name
    })
    .map((vnode: VNode) => vnode.props?.['layer-id'] || vnode.props?.id)
    .filter((id: unknown) => id != null)

  return layerIds as string[]
})

// Sort the layers while respecting both the slot order and the beforeId
const orderedLayers = computed(() => {
  // Create a Map to store the order of the layers in the slot
  const slotOrder = new Map(
    slotLayersOrder.value.map((id: string, index: number) => [id, index]),
  )

  // First sort based on existing rules
  const initialSortedLayers = [...layers.value].sort((a, b) => {
    // If the two layers have a beforeId, compare them
    if (a.beforeId && b.beforeId) {
      if (a.beforeId === b.id) {
        return 1
      }
      if (b.beforeId === a.id) {
        return -1
      }
    } else if (a.beforeId) {
      // If only one layer has a beforeId
      if (a.beforeId === b.id) {
        return 1
      }
    } else if (b.beforeId) {
      if (b.beforeId === a.id) {
        return -1
      }
    }

    // Check if the layers correspond to slot ids
    const aSlotId = slotLayersOrder.value.find((id: string) =>
      a.id === id || a.id.startsWith(`${id}-`),
    )
    const bSlotId = slotLayersOrder.value.find((id: string) =>
      b.id === id || b.id.startsWith(`${id}-`),
    )

    // If the two layers correspond to slot ids, use the slot order
    if (aSlotId && bSlotId) {
      const aOrder = slotOrder.get(aSlotId) ?? 0
      const bOrder = slotOrder.get(bSlotId) ?? 0
      return aOrder - bOrder
    } else if (aSlotId) {
      return -1
    } else if (bSlotId) {
      return 1
    }

    // If no layer corresponds to a slot id, keep the initial order
    return 0
  })

  // Second step: adjust the order based on the beforeId for layers in groups
  const result = [...initialSortedLayers]

  // Adjust the order based on the beforeId for layers in groups
  for (let i = 0; i < result.length; i++) {
    const layer = result[i]

    if (layer.beforeId) {
      const targetLayerIndex = result.findIndex(l => l.id === layer.beforeId)

      // If the target layer exists and the current layer is after the target layer
      if (targetLayerIndex !== -1 && i > targetLayerIndex) {
        result.splice(i, 1)
        result.splice(targetLayerIndex, 0, layer)
        i--
      }
    }
  }

  return result
})

provide(LayerRegistryKey, {
  register: (layer: LayerInfo) => {
    layers.value.push(layer)
  },
  unregister: (id: string) => {
    layers.value = layers.value.filter(l => l.id !== id)
  },
  layers,
})

// reorder layers when the order changes
watch(orderedLayers, (newLayers) => {
  const mapRef = map.value
  if (!mapRef) {
    return
  }

  // Iterate through layers from bottom to top (first rendered to last rendered)
  for (let i = 0; i < newLayers.length; i++) {
    const currentLayer = newLayers[i]

    // Find the current position of the layer in the map
    const currentPosition = mapRef.getStyle().layers.findIndex(
      (l: any) => l.id === currentLayer.id,
    )

    // Calculate the target position in the map
    // The layers in newLayers are already sorted in the desired order
    const targetPosition = i

    // If the layer is not in the correct position, move it
    if (currentPosition !== -1 && currentPosition !== targetPosition) {
      // If it's not the first layer, place it after the previous layer
      if (i > 0) {
        const beforeLayerId = newLayers[i - 1].id
        mapRef.moveLayer(currentLayer.id, beforeLayerId)
      } else {
        // If it's the first layer, place it first
        mapRef.moveLayer(currentLayer.id, undefined)
      }
    }
  }
})
</script>

<template>
  <slot />
</template>
